import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Layout } from "../../Layout";
import { Container } from "../../styles/pages/Expedicion.styles";

const Libro = () => {
  return (
    <Layout title="Libro">
      <Container>
        <StaticImage
          src="../../images/libro/1.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <p>
          Las fotografías de la expedición junto a los testimonios de sus
          participantes serán posteriormente digitalizadas en un libro editado
          por la editorial ZUNIZINES, de manera de generar un producto tangible
          y reproducible para ser distribuido a instituciones, empresas, museos,
          colegios, universidades, etc.
        </p>

        <hr />

        <StaticImage
          src="../../images/libro/detalle.jpg"
          alt="imagen"
          placeholder="blurred"
        />
      </Container>
    </Layout>
  );
};

export default Libro;
